/* width */
.width-80 {
    width: 80px !important;
}

.min-width-166 {
    min-width: 166px !important;
}

.width-220 {
    width: 220px !important;
}

.width-330 {
    width: 330px !important;
}

/* width */

/* height */

.height-42 {
    height: 42px !important;
}


.height-100-pct {
    height: 100% !important;
}

.height-150 {
    height: 150px !important;
}

.height-600 {
    height: 600px !important;
}

/* height */