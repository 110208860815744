ion-button{
  height: 41px;
  --border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
}
ion-toolbar{
  --min-height: 50px;
}

.common-user-form {
  padding-top: 10px;
  padding-bottom: calc(var(--ion-safe-area-bottom) + 26px);
  .section-label {
    color: #243548;
    font-size: 16px;
    font-weight: bold;
    margin-left: 20px;
    line-height: 24px;
    margin-bottom: 6px;
    display: block;
  }
  ion-item {
    --min-height: 48px;
    &::part(detail-icon) {
      opacity: 1;
      color: #92949c;
      font-size: 24px;
    }
    ion-icon {
      color: #92949c;
      font-size: 24px;
    }
    ion-input {
      font-size: 16px;
      --placeholder-color: #92949c;
      --placeholder-opacity: 1;
      --color: #92949c;
    }
    ion-note {
      font-size: 12px;
      color: #666;
      margin-right: 16px;
    }
  }

  section {
    margin-top: 22px;
  }
  .save-btn {
    margin-left: 20px;
    margin-right: 20px;
  }
  .common-toggle {
    height: 28px;
    --handle-width: 22px;
    --handle-height: 22px;
    width: 48px;
    --handle-spacing: 3px;
  }
  .helper-label {
    color: #666666;
    font-size: 12px;
    margin-left: 40px;
    display: block;
    margin-top: 6px;
    margin-bottom: 16px;
  }
  .company-modal {
    ion-toolbar {
      --background: #ffff;
    }
  }
  .error-message {
    margin-left: 64px;
  }
}
.secondary-btn {
  --border-color: #757575;
}
.qr-code-scanner {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 22px;
  ion-col {
    box-shadow: 0 1px 0 2px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
    ion-label {
      color: #000;
      font-weight: 600;
      font-size: 14px;
    }
  }
  .barcode-icon {
    width: 134px;
    height: 64px;
  }
  .qr-icon {
    font-size: 64px;
  }
}
.footer-btn {
  ion-toolbar {
    --background: #fff;
  }
  ion-button {
    --border-radius: 4px;
  }
}
.placeholder-opacity-1 {
  --placeholder-opacity: 1;
}
.cancel-button-scan {
  position: absolute;
  bottom: 100px;
  z-index: 11;
  left: 13%;
  width: 300px;
}
.torch-btn {
  position: absolute;
  top: var(--ion-safe-area-top);
  left: 8px;
}