.error-message {
    min-height: 2.2rem !important;

    ion-label {
        margin: 2px 0 !important;
        font-size: 14px !important;
        color: #ff6153 !important;
    }

    .item-inner {
        border-bottom: 0 !important;
    }
}

.box-shadow-none {
    box-shadow: none !important;
}

.text-decoration-underline {
    text-decoration: underline !important;
}

.text-transform-none {
    text-transform: none !important;
}

.virtual-scroller-viewport {
    height: 630px !important;
    width: 100% !important;
}


.border-style-groove {
    border-style: groove !important;
}

body.scanner-active {
    --background: transparent !important;
    --ion-background-color: transparent !important;
}

.border-radius-5 {
    border-radius: 5px !important;
}

.top-border {
    border-top-style: solid !important;
    border-width: 1px !important;
    border-color: lightgray !important;
}

.bottom-border {
    border-bottom-style: solid !important;
    border-width: 1px !important;
    border-color: lightgray !important;
}

.scan-box {
    border: 2px solid #fff !important;
    box-shadow: 0 0 0 100vmax rgb(0, 0, 0, 0.5) !important;
    content: "" !important;
    display: block !important;
    left: 50% !important;
    position: absolute !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
}

.rounded-image {
    border-radius: 50% !important;
}

.remove-outline {
    outline: none !important;
}

.border-none {
    border: none !important;
}

/* Ionic styles */

.alert-wrapper {
    min-width: 320px !important;
}

/* Ionic styles */


//Google map Styles

.gm-style .gm-style-iw-c {
    min-width: 340px !important;
}

//Google map Styles