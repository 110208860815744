/* color */

.color-tertiary {
    color: var(--ion-color-tertiary) !important;
}

.color-light-gray {
    color: var(--color-light-gray) !important;
}

.color-secondary {
    color: var(--ion-color-secondary) !important;
}


.color-white {
    color: white !important;
}

.color-gray {
    color: gray !important;
}

.color-black {
    color: black !important;
}
.color-danger {
    color: var(--ion-color-danger) !important;
}

/* color */

/* background */

.background-color-dark-blue {
    background-color: #243548 !important;
}

.background-color-very-light-gray {
    background-color: #F5F6F9 !important;
}

.background-color-success {
    background-color: var(--ion-color-success) !important;
}

.background-color-danger {
    background-color: var(--ion-color-danger) !important;
}

.background-color-primary {
    background-color: var(--ion-color-primary) !important;
}

.background-color-secondary {
    background-color: var(--ion-color-secondary) !important;
}


.background-color-black {
    background-color: black !important;
}

/* background */