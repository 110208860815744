@font-face {
    font-family: "OpenSans-Regular";
    src: url("../assets/fonts/OpenSans-Regular.ttf");
}

@font-face {
    font-family: "OpenSans-Bold";
    src: url("../assets/fonts/OpenSans-Bold.ttf");
}


* {
    font-family: "OpenSans-Regular";
}

.font-bold {
    font-family: "OpenSans-Bold" !important;
}

.font-style-italic {
    font-style: italic !important;
}
.font-size-10 {
    font-size: 10px !important;
}

.font-size-12 {
    font-size: 12px !important;
}

.font-size-13 {
    font-size: 13px !important;
}

.font-size-14 {
    font-size: 14px !important;
}

.font-size-15 {
    font-size: 15px !important;
}

.font-size-16 {
    font-size: 16px !important;
}

.font-size-17 {
    font-size: 17px !important;
}

.font-size-20 {
    font-size: 20px !important;
}

.font-size-24 {
    font-size: 24px !important;
}

.font-size-25 {
    font-size: 25px !important;
}

.font-size-30 {
    font-size: 30px !important;
}

.font-size-32 {
    font-size: 32px !important;
}

.font-size-40 {
    font-size: 40px !important;
}

.font-size-50 {
    font-size: 50px !important;
}