.top-minus-30 {
    top: -30px !important;
}

/* margin */
.margin-left-minus-31 {
    margin-left: -31px !important;
}

.margin-top-minus-20 {
    margin-top: -20px !important;
}

.margin-top-minus-16 {
    margin-top: -16px !important;
}

.margin-top-minus-10 {
    margin-top: -10px !important;
}

.margin-right-minus-8 {
    margin-right: -8px !important;
}

.margin-left-minus-5 {
    margin-left: -5px !important;
}

.margin-top-minus-6 {
    margin-top: -6px !important;
}

.margin-left-3 {
    margin-left: 3px !important;
}

.margin-top-5 {
    margin-top: 5px !important;
}

.margin-horizontal-7 {
    margin-left: 7px !important;
    margin-right: 7px !important;
}


.margin-top-8 {
    margin-top: 8px !important;
}

.margin-top-10 {
    margin-top: 10px !important;
}

.margin-left-10 {
    margin-left: 10px !important;
}

.margin-right-10 {
    margin-right: 10px !important;
}

.margin-left-11 {
    margin-left: 11px !important;
}

.margin-top-12 {
    margin-top: 12px !important;
}

.margin-top-14 {
    margin-top: 14px !important;
}

.margin-left-15 {
    margin-left: 15px !important;
}


.margin-left-19 {
    margin-left: 19px !important;
}

.margin-top-18 {
    margin-top: 18px !important;
}

.margin-top-20 {
    margin-top: 20px !important;
}

.margin-top-25 {
    margin-top: 25px !important;
}

.margin-left-24 {
    margin-left: 24px !important;
}

.margin-top-40 {
    margin-top: 40px !important;
}

.margin-top-70 {
    margin-top: 70px !important;
}

.margin-top-80 {
    margin-top: 80px !important;
}

.margin-top-150 {
    margin-top: 150px !important;
}

/* margin */

/* padding */

.padding-vertical-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.padding-horizontal-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.padding-top-0 {
    padding-top: 0 !important;
}

.padding-bottom-0 {
    padding-bottom: 0 !important;
}

.padding-left-0 {
    padding-left: 0 !important;
}

.padding-left-2 {
    padding-left: 2px !important;
}

.padding-left-5 {
    padding-left: 5px !important;
}

.padding-left-10 {
    padding-left: 10px !important;
}

.padding-vertical-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}


.padding-left-30 {
    padding-left: 30px !important;
}

/* padding */

/* bottom */

.bottom-70 {
    bottom: 70px !important;
}

/* bottom */

/*iOS only*/

.ios .margin-top-12-ios {
    margin-top: 12px !important;
}

.ios .margin-left-15-ios {
    margin-left: 15px !important;
}

.md .margin-left-10-md {
    margin-left: 10px !important;
}

.md .margin-left-minus-36-md {
    margin-left: -36px !important;
}

.ios .margin-left-minus-31-ios {
    margin-left: -31px !important;
}

.ios .margin-left-3-ios {
    margin-left: 3px !important;
}

.md .margin-left-minus-3-md {
    margin-left: -3px !important;
}

.ios .margin-top-70-ios {
    margin-top: 70px !important;
}

/*iOS only*/